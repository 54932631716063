<template>
  <section class="trip-options weather">
    <h3>
      Weather
      <span class="links">
        <a
          href
          @click.prevent="toggleWeather('start')"
          class="start"
          :class="{ active: weatherStart }"
        >
          {{ origin }}
          <span class="el-offscreen" v-show="weatherStart"> - active</span></a
        >
        <a
          href
          class="finish"
          :class="{ active: weatherEnd }"
          @click.prevent="toggleWeather('end')"
        >
          {{ destination }}
          <span class="el-offscreen" v-show="weatherEnd"> - active</span></a
        >
      </span>
    </h3>
    <div class="inner">
      <div class="start weather-list" v-show="weatherStart">
        <div
          class="day"
          v-for="(day, index) in weatherData[0].list"
          :key="index"
        >
          <h4 class="day-of-week">{{ getDay(day.dt) }}</h4>
          <ul>
            <li class="img">
              <img
                :src="require(`../assets/img/${day.weather[0].icon}.png`)"
                width="31"
                height="27"
                :alt="day.weather[0].description"
              />
            </li>
            <li class="high">
              {{ day.temp.max | convertToC }}&deg;
              <span class="label">Max</span>
            </li>
            <li class="low">
              {{ day.temp.min | convertToC }}&deg;
              <span class="label">Min</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="finish weather-list" v-if="hasEnd && weatherEnd">
        <div
          class="day"
          v-for="(day, index) in weatherData[1].list"
          :key="index"
        >
          <h4 class="day-of-week">{{ getDay(day.dt) }}</h4>
          <ul>
            <li class="img">
              <img
                :src="require(`../assets/img/${day.weather[0].icon}.png`)"
                width="31"
                height="27"
                :alt="day.weather[0].description"
              />
            </li>
            <li class="high">
              {{ day.temp.max | convertToC }}&deg;
              <span class="label">Max</span>
            </li>
            <li class="low">
              {{ day.temp.min | convertToC }}&deg;
              <span class="label">Min</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { fromUnixTime, format } from "date-fns";
export default {
  name: "TripWeather",
  data() {
    return {
      weatherStart: true,
      weatherEnd: false
    };
  },
  methods: {
    getDay(date) {
      return format(fromUnixTime(date), "eee");
    },
    toggleWeather: function(key) {
      switch (key) {
        case "start":
          this.weatherStart = true;
          this.weatherEnd = false;
          break;
        case "end":
          this.weatherEnd = true;
          this.weatherStart = false;
          break;
      }
    }
  },
  computed: {
    hasWeather() {
      return this.weatherData.every(element => element === null);
    },
    hasEnd() {
      return this.weatherData.length > 1;
    }
  },
  props: {
    weatherData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    origin: {
      type: String,
      default: ""
    },
    destination: {
      type: String,
      default: ""
    }
  },
  filters: {
    convertToC(k) {
      return Math.round(k - 273.15);
    }
  }
};
</script>
