<template>
  <a :href="`mailto:?subject=${subject}&body=${mailtoBody}`" class="email">{{
    mailtoTripLabel
  }}</a>
</template>

<script>
export default {
  name: "EmailTrip",
  props: {
    subject: {
      type: String,
      default: ""
    },
    bodyTitle: {
      type: String,
      default: ""
    },
    bodySummary: {
      type: String,
      default: ""
    },
    bodyLink: {
      type: String,
      default: ""
    },
    mailtoTripLabel: {
      type: String,
      default: "Email trip"
    }
  },
  computed: {
    mailtoBody() {
      // NOTE: "%0D%0A%0D%0A" will add one line break.
      // https://stackoverflow.com/a/4782084
      const body = [
        "Hi,%0D%0A%0D%0A",
        "Check out this review of " +
          this.bodyTitle +
          ", including scenery, hazards and stops that I found on ridetolive.nsw.gov.au %0D%0A%0D%0A",
        this.bodySummary + "%0D%0A%0D%0A",
        this.bodyLink + "%0D%0A%0D%0A",
        "What do you think?"
      ];
      return body.join("");
    }
  }
};
</script>
