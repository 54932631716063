<template>
  <span>
    <div class="col-xs-6">
      <div class="inner">
        <h3>Like this ride?</h3>
      </div>
    </div>
    <ul class="col-xs-6">
      <li>
        <a
          href=""
          class="yes"
          @click.prevent="
            voteUp({
              tripId: votes.id,
              upVoteCount: parseInt(upVoteCount, 10) + 1
            })
          "
        >
          <img src="../assets/img/vote-up.png" alt="vote up" />
          <span class="label"> Yes</span>
          <span class="count">({{ upVoteCount }})</span>
        </a>
      </li>
      <li>
        <a
          href=""
          class="no"
          @click.prevent="
            voteDown({
              tripId: votes.id,
              downVoteCount: parseInt(downVoteCount, 10) + 1
            })
          "
        >
          <img src="../assets/img/vote-down.png" alt="vote down" />
          <span class="label"> No</span>
          <span class="count">({{ downVoteCount }})</span>
        </a>
      </li>
    </ul>
  </span>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { SET_UPVOTE, SET_DOWNVOTE } from "@/store/mutation-type";
import { UP_VOTE_REQUEST, DOWN_VOTE_REQUEST } from "@/store/actions-type";

export default {
  name: "Vote",
  props: {
    votes: {
      type: Object,
      default: () => {
        return {};
      }
    },
    tripId: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapState("trip", ["upVoteCount", "downVoteCount"])
  },
  methods: {
    ...mapActions("trip", {
      voteUp: `${UP_VOTE_REQUEST}`,
      voteDown: `${DOWN_VOTE_REQUEST}`
    }),
    ...mapMutations("trip", {
      setUpvote: `${SET_UPVOTE}`,
      setDownvote: `${SET_DOWNVOTE}`
    })
  }
};
</script>
