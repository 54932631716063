<template>
  <div id="accordion" class="panel-group">
    <Accordion
      v-for="item in data"
      :key="item.id"
      :id="item.id"
      :title="item.field_title"
      :description="item.field_description"
    />
  </div>
</template>

<script>
import Accordion from "@/components/Accordion.vue";
export default {
  name: "AccordionGroup",
  components: { Accordion },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      }
    }
  }
};
</script>
