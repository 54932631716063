<template>
  <div>
    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          <a
            :href="`#section-hazard-${this.id}`"
            :class="{ collapsed: !isOpen }"
            @click.prevent="toggle()"
          >
            <span class="el-offscreen accessibility-label">{{
              isOpen ? "Show" : "Hide"
            }}</span
            >{{ this.title }}</a
          >
        </h4>
      </div>
      <transition
        enter-active-class="enter-active"
        leave-active-class="leave-active"
        @before-enter="beforeEnter"
        @enter="enter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @leave="leave"
        @after-leave="afterLeave"
      >
        <div
          :id="`#section-hazard-${this.id}`"
          class="panel-collapse"
          ref="panelBody"
          v-show="isOpen"
        >
          <div class="panel-body">
            <p>{{ this.description }}</p>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordion",
  props: {
    id: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    beforeEnter(el) {
      requestAnimationFrame(() => {
        if (!el.style.height) {
          el.style.height = "0px";
        }

        el.style.display = null;
      });
    },
    enter(el) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          el.style.height = `${el.scrollHeight}px`;
        });
      });
    },
    afterEnter(el) {
      el.style.height = null;
    },
    beforeLeave(el) {
      requestAnimationFrame(() => {
        if (!el.style.height) {
          el.style.height = `${el.offsetHeight}px`;
        }
      });
    },
    leave(el) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          el.style.height = "0px";
        });
      });
    },
    afterLeave(el) {
      el.style.height = null;
    }
  }
};
</script>
<style>
.enter-active,
.leave-active {
  overflow: hidden;
  transition: height 0.5s;
}
</style>
