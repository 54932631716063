<template>
  <div class="trips-carousel trip-photos-carousel">
    <div class="inner" v-if="images">
      <h3 class="el-offscreen">Carousel</h3>
      <Carousel
        :indicators="indicators"
        :controls="controls"
        :interval="interval"
        icon-control-left="left-arrow"
        icon-control-right="right-arrow"
        ref="trips-carousel"
        class="trips-carousel"
      >
        <Slide v-for="(slide, index) in images" :key="index">
          <div
            :style="{ backgroundImage: `url('${slide.name}')` }"
            class="item"
            @click="showLightbox(slide.caption)"
          ></div>
          <div class="carousel-caption">
            <h3></h3>
          </div>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "uiv";

export default {
  name: "TripCarousel",
  components: {
    Carousel,
    Slide
  },
  props: {
    images: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      interval: 4000,
      indicators: false,
      controls: true,
      galleryFilter: "all"
    };
  },
  methods: {
    showLightbox: function(id) {
      this.$parent.$refs.lightbox.show(id);
    }
  }
};
</script>
<style lang="scss">
.carousel-control.right {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.0001) 0,
    rgba(0, 0, 0, 0.3) 100%
  );
}
.carousel-control .left-arrow {
  background: asset("img/carousel-left.png") no-repeat;
  left: auto;
  position: absolute;
  top: 40%;
  z-index: 5;
  display: inline-block;
}
.carousel-control .right-arrow {
  background: asset("img/carousel-right.png") no-repeat;
  left: auto;
  position: absolute;
  top: 40%;
  z-index: 5;
  display: inline-block;
}
</style>
