<template>
  <ol class="explore-toggles">
    <li class="scenic" v-if="this.scenic.length">
      <Toggle
        name="scenic"
        label="Scenic spots"
        :isChecked="true"
        :toggleData="this.scenic"
      />
    </li>
    <li class="rest" v-if="this.rest.length">
      <Toggle name="rest" label="Rest areas" :toggleData="this.rest" />
    </li>
    <li class="crashes" v-if="this.crash.length">
      <Toggle name="crash" label="Recent crashes *" :toggleData="this.crash" />
    </li>
    <li class="parking" v-if="this.parking.length">
      <Toggle
        name="parking"
        label="CBD Parking"
        :isChecked="true"
        :toggleData="this.parking"
      />
    </li>
    <li class="traffic">
      <Toggle name="traffic" label="Live Traffic" />
    </li>
    <li class="fuel" v-if="this.fuel.length">
      <Toggle name="fuel" label="Fuel" :toggleData="this.fuel" />
    </li>
    <li class="food" v-if="this.food.length">
      <Toggle name="food" label="Food" :toggleData="this.food" />
    </li>
    <li class="accommodation" v-if="this.accommodation.length">
      <Toggle
        name="accommodation"
        label="Accommodation"
        :toggleData="this.accommodation"
      />
    </li>
    <li class="camping" v-if="this.camping.length">
      <Toggle name="camping" label="Camping" :toggleData="this.camping" />
    </li>
    <li class="satellite" v-if="this.satellite.length">
      <Toggle
        name="satellite"
        label="Satellite phones"
        :toggleData="this.satellite"
      />
    </li>
  </ol>
</template>

<script>
import Toggle from "@/components/Toggle";

export default {
  name: "TripExplore",
  components: { Toggle },
  props: {
    tripMapData: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  mounted() {},
  computed: {
    parking() {
      return this.tripMapData.filter(item => item.type === "parking");
    },
    scenic() {
      return this.tripMapData.filter(item => item.type === "scenic");
    },
    rest() {
      return this.tripMapData.filter(item => item.type === "rest");
    },
    crash() {
      return this.tripMapData.filter(item => item.type === "crash");
    },
    fuel() {
      return this.tripMapData.filter(item => item.type === "fuel");
    },
    food() {
      return this.tripMapData.filter(item => item.type === "food");
    },
    accommodation() {
      return this.tripMapData.filter(item => item.type === "accommodation");
    },
    camping() {
      return this.tripMapData.filter(item => item.type === "camping");
    },
    satellite() {
      return this.tripMapData.filter(item => item.type === "satellite");
    }
  }
};
</script>

<style></style>
