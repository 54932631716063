<template>
  <div>
    <label :for="`poi-${this.name}`" class="label">{{ this.label }}</label>
    <a
      href=""
      @click.prevent="switchUpdate()"
      class="toggle"
      aria-hidden="true"
    >
      <input
        :id="`poi-${this.name}`"
        type="checkbox"
        :name="this.name"
        class="js-switch"
        :checked="this.checked"
        ref="switch"
      />
    </a>
    <ul class="el-offscreen">
      <li v-for="poi in toggleData" :key="poi.id">
        {{ poi.title }}
      </li>
    </ul>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { mapState, mapMutations } from "vuex";
import { TRAFFIC_LAYER_ON, TRAFFIC_LAYER_OFF } from "@/store/mutation-type";
export default {
  name: "Toggle",
  props: {
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    isChecked: {
      type: Boolean,
      default: false
    },
    toggleData: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      checked: this.isChecked
    };
  },
  mounted() {
    this.$nextTick(() => {
      new Switchery(this.$refs.switch, {
        color: "#FFF",
        secondaryColor: "#E5E9EC",
        jackColor: "#FFD300",
        jackSecondaryColor: "#b5bcc5"
      });
    });
  },
  computed: {
    ...mapState("trip", ["map", "markers"])
  },
  methods: {
    ...mapMutations("trip", {
      setTrafficLayerOn: `${TRAFFIC_LAYER_ON}`,
      setTrafficLayerOff: `${TRAFFIC_LAYER_OFF}`
    }),
    switchUpdate() {
      // Toggle traffic layer
      if (this.name === "traffic") {
        if (this.checked) {
          this.setTrafficLayerOff();
        } else {
          this.setTrafficLayerOn();
        }
      }

      // Toggle different type of POI markers
      this.markers.forEach(marker => {
        if (marker.poiType === this.name) {
          if (this.checked) {
            marker.setMap(null);
          } else {
            marker.setMap(this.map);
          }
        }
      });
      this.checked = !this.checked;
    }
  }
};
</script>
