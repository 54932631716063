<template>
  <div class="layout-trips full-height">
    <Preloader :loading="isLoading" />
    <Modal
      v-model="modalOpen"
      id="globalMessagesModal"
      class="global-messages-modal"
    >
      <template v-slot:header>
        <a class="close" @click="closeModal()">
          <span aria-hidden="true">&times;</span>
          <span class="sr-only">Close</span>
        </a>
        <h4 class="modal-title">{{ modalTitle }}</h4>
      </template>
      <p>{{ modalBody }}</p>
      <template v-slot:footer>
        <ul class="one_link">
          <li>
            <a href="#" class="btn" @click="closeModal()">{{ "CLOSE" }}</a>
          </li>
        </ul>
      </template>
    </Modal>
    <Lightbox
      ref="lightbox"
      :images="getTripImagesLarge"
      :timeoutDuration="5000"
    ></Lightbox>

    <div class="mobile-explore-menu white-icons" v-show="sidePoiPanelVisible">
      <TripExplore :tripMapData="trip.json_map_data" />
    </div>
    <div class="page-wrapper">
      <section class="trip-map col-sm-12 col-md-12 col-lg-12">
        <div class="title">
          <router-link :to="{ name: 'trips' }" tag="a" class="back">
          </router-link>
          <h1>{{ this.title }}</h1>
        </div>
        <div class="show-details-toggle">
          <a href v-show="!sidePanelVisible" @click.prevent="toggleSidePanel()"
            >Show details<i></i
          ></a>
        </div>
        <div class="mobile-controls">
          <a
            class="details"
            @click.prevent="togglePoiPanel()"
            v-show="mapExpanded == false"
            >Show map details</a
          >
          <a
            class="expand"
            :class="{ active: !sidePanelVisible }"
            @click.prevent="
              toggleExpandMap({ mobile: true }), toggleSidePanel()
            "
            >Expand map</a
          >
        </div>
        <GoogleMap
          id="trip-google-map"
          name="TripDetailMap"
          :mapConfig="mapConfig"
          :mapData="tripGeoData"
        />
      </section>

      <aside
        id="trip-selector-side-panel"
        class="panel panel-trip-details col-sm-5 col-md-4 col-lg-3"
        :class="{
          slideInLeft: !isLoading && sidePanelVisible,
          'slide-offscreen-left': !sidePanelVisible
        }"
      >
        <div class="inner">
          <section class="top">
            <div id="trip-photos-carousel">
              <TripCarousel
                v-if="trip.field_trip_type === 'recreational'"
                :images="getTripImages"
              />
            </div>
            <div
              class="mobile-carousel"
              v-if="
                getTripImages.length && trip.field_trip_type === 'recreational'
              "
            >
              <ul>
                <li
                  v-for="image in getTripImages"
                  :key="image.id"
                  :style="{ backgroundImage: `url('${image.name}')` }"
                  @click="showLightbox(image.caption)"
                ></li>
              </ul>
            </div>
            <div class="title">
              <router-link
                :to="{ name: 'trips' }"
                tag="a"
                class="back"
                @mouseover.native="swapTitle(1)"
                @mouseleave.native="swapTitle(0)"
              >
                Back</router-link
              >

              <h1>{{ this.title }}</h1>
              <a href @click.prevent="toggleSidePanel()" class="close">Close</a>
            </div>
          </section>
          <section class="trip-info">
            <h2
              v-if="
                trip.field_origin &&
                  trip.field_destination &&
                  trip.field_trip_type == 'recreational'
              "
            >
              {{ trip.field_origin }} to {{ trip.field_destination }}
            </h2>
            <ul
              class="stats clearfix"
              v-if="trip.field_trip_type == 'recreational'"
            >
              <li class="distance">
                <span class="numbers"
                  >{{ Math.round(trip.field_length)
                  }}<span class="unit">KM</span></span
                >
              </li>
              <li class="duration">
                <span class="numbers">
                  <span class="hours" v-if="trip.hours > 0">
                    {{ trip.hours }}<span class="unit">HRS</span>
                  </span>
                  <span class="minutes" v-if="trip.minutes > 0">
                    {{ trip.minutes }}<span class="unit">MINS</span>
                  </span>
                </span>
              </li>
            </ul>
            <p class="label">
              Distance and time are approximate. Remember to take regular breaks
              when riding.
            </p>
            <div
              class="directions"
              v-if="trip.field_trip_type == 'recreational'"
            >
              <a target="_blank" :href="this.googleRoute"
                >Get directions on Google Maps</a
              >
            </div>
            <div class="description">
              <p>{{ trip.field_description }}</p>
            </div>
          </section>
          <section class="trip-options explore">
            <h3>Explore</h3>
            <div class="inner">
              <TripExplore :tripMapData="trip.json_map_data" />
            </div>
          </section>
          <section
            class="trip-options hazards"
            v-if="trip.field_hazards.length"
          >
            <h3>Hazards</h3>
            <div class="inner">
              <AccordionGroup :data="trip.field_hazards" />
            </div>
          </section>
          <section
            class="trip-options top-highlights"
            v-if="trip.field_top_highlights.length"
          >
            <h3>Top Highlights</h3>
            <div class="inner">
              <AccordionGroup :data="trip.field_top_highlights" />
            </div>
          </section>
          <TripWeather
            v-if="!isWeatherEmpty"
            :weatherData="getWeatherData"
            :origin="trip.field_origin"
            :destination="trip.field_destination"
          />
          <section
            class="trip-options vote"
            v-if="trip.field_trip_type === 'recreational'"
          >
            <Vote :votes="trip" />
          </section>
          <section class="trip-options share">
            <ul>
              <li>
                <EmailTrip
                  :subject="`Ride to live: ${trip.title}`"
                  :bodyTitle="`${trip.title}`"
                  :bodySummary="`${trip.title}`"
                  :bodyLink="`https://ridetolive.nsw.gov.au${trip.field_slug}`"
                />
              </li>
              <li>
                <SocialShare
                  socialMediaType="facebook"
                  socialLinkLabel="Share trip"
                />
              </li>
            </ul>
          </section>
        </div>
      </aside>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import store from "@/store";
import { FETCH_TRIP } from "@/store/actions-type";
import {
  LOADING_START,
  LOADING_END,
  MAP_EXPANDED_ON,
  MAP_EXPANDED_OFF,
  OPEN_MODAL,
  CLOSE_MODAL
} from "@/store/mutation-type";
import {
  GET_TRIP,
  GET_TRIP_POI,
  GET_METADATA,
  GET_TRIP_IMAGES_LARGE,
  GET_TRIP_IMAGES,
  GET_WEATHER_DATA
} from "@/store/getters-type";
import { Modal } from "uiv";
import Preloader from "@/components/Preloader.vue";
import SocialShare from "@/components/SocialShare.vue";
import EmailTrip from "@/components/EmailTrip.vue";
import TripExplore from "@/components/TripExplore.vue";
import AccordionGroup from "@/components/AccordionGroup.vue";
import GoogleMap from "@/components/GoogleMap";
import TripCarousel from "@/components/TripCarousel.vue";
import Lightbox from "@/components/Lightbox";
import Vote from "@/components/Vote";
import TripWeather from "@/components/TripWeather";

let bodyTag = null;

export default {
  name: "trips-detail",
  components: {
    Preloader,
    SocialShare,
    EmailTrip,
    TripExplore,
    GoogleMap,
    AccordionGroup,
    TripCarousel,
    Lightbox,
    Vote,
    Modal,
    TripWeather
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch(`trip/${FETCH_TRIP}`, to.fullPath);
    store.commit(`ui/${LOADING_START}`);
    next(vm => {
      vm.$store.commit(`ui/${LOADING_END}`);
    });
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.getMeta
    };
  },
  data() {
    return {
      title: "",
      bodyClass: "page-trips",
      sidePanelVisible: true,
      mapExpanded: false,
      sidePoiPanelVisible: false
    };
  },
  mounted() {
    this.title = this.trip.title;
    bodyTag = document.getElementsByTagName("body")[0];
    bodyTag.classList.add(this.bodyClass);
  },
  computed: {
    ...mapState({
      isLoading: state => state.ui.isLoading,
      mapConfig: state => state.trip.mapConfig,
      modalOpen: state => state.trip.modalOpen,
      modalTitle: state => state.trip.modalTitle,
      modalBody: state => state.trip.modalBody
    }),
    isWeatherEmpty() {
      return this.getWeatherData.every(element => element === null);
    },
    ...mapGetters("trip", {
      trip: `${GET_TRIP}`,
      tripGeoData: `${GET_TRIP_POI}`,
      getMeta: `${GET_METADATA}`,
      getTripImagesLarge: `${GET_TRIP_IMAGES_LARGE}`,
      getTripImages: `${GET_TRIP_IMAGES}`,
      getWeatherData: `${GET_WEATHER_DATA}`
    }),

    googleRoute() {
      let path = "https://maps.google.com?";
      const tripRoute = this.trip.json_map_data.filter(item => {
        return item.type === "trip";
      });

      tripRoute.forEach((route, index) => {
        switch (index) {
          case 0:
            path = path + "saddr=" + route.lat + "," + route.lng;
            break;
          case 1:
            path = path + "&daddr=" + route.lat + "," + route.lng;
            break;
          default:
            path = path + "+to:" + route.lat + "," + route.lng;
            break;
        }
      });
      return path;
    }
  },
  methods: {
    ...mapMutations("trip", {
      setExpandedOn: `${MAP_EXPANDED_ON}`,
      setExpandedOff: `${MAP_EXPANDED_OFF}`,
      openModal: `${OPEN_MODAL}`,
      closeModal: `${CLOSE_MODAL}`
    }),
    toggleBodyClass(className) {
      bodyTag.classList.toggle(className);
    },
    toggleExpandMap() {
      if (!this.mapExpanded) {
        this.setExpandedOn();
        this.mapExpanded = true;
        this.toggleBodyClass("trip-map-is-fullscreen");
        // Hide POI panel if showing
        if (this.sidePoiPanelVisible) {
          this.togglePoiPanel();
        }
      } else {
        this.setExpandedOff();
        this.mapExpanded = false;
        this.toggleBodyClass("trip-map-is-fullscreen");
      }
    },

    toggleSidePanel() {
      this.sidePanelVisible = !this.sidePanelVisible;
    },
    togglePoiPanel() {
      this.sidePoiPanelVisible = !this.sidePoiPanelVisible;
      this.toggleBodyClass("explore-menu-open");
    },

    ...mapActions("trip", {
      fetchTrip: `${FETCH_TRIP}`
    }),
    swapTitle(toggle) {
      this.title = toggle ? "Back to trips" : this.trip.title;
    },
    showLightbox: function(id) {
      this.$refs.lightbox.show(id);
    }
  }
};
</script>
